<template>
  <Line
    ref="chartRef"
    id="chart-analytics-purchases"
    :options="options"
    :data="chartData"
  />
</template>
<script setup>
import { ref, watch } from "vue";
import { Line } from 'vue-chartjs';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
const options = {
  responsive: true,
  // maintainAspectRatio: false
}

const props = defineProps({
  data: {
    type: Object,
  },
})

const dataCreator = (responseData) => {
  const {
    labels,
    added,
    added_last_period,
    extended,
    extended_last_period,
    added_sum,
    added_last_period_sum,
    extended_sum,
    extended_last_period_sum
  } = responseData;

  return {
    labels,
    datasets: [
      {
        label: `Выбраный период (куплено). Всего: ${added_sum}`,
        backgroundColor: '#86f879',
        borderColor: '#8aef6a',
        borderWidth: 2.5,
        data: added
      },
      {
        label: `Прошлый период  (куплено). Всего: ${added_last_period_sum}`,
        backgroundColor: '#C4E3BBFF',
        borderColor: '#c3e3ba',
        borderWidth: 2,
        data: added_last_period,
        borderDash: [7,2],
      },
      {
        label: `Выбраный период (продлено). Всего: ${extended_sum}`,
        backgroundColor: '#7997f8',
        borderColor: '#6b8aea',
        borderWidth: 2.5,
        data: extended,
      },
      {
        label: `Прошлый период  (продлено) ${extended_last_period_sum}`,
        backgroundColor: '#c0cdf6',
        borderColor: '#b1bfee',
        borderWidth: 2,
        data: extended_last_period,
        borderDash: [7,2],
      },
    ]
  }
};
let chartData = ref(dataCreator({}));


watch(() => props.data, async (newData, oldData) => {
  chartData.value = dataCreator(newData)
}, { immediate: true });
</script>
