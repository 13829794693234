<template>
  <div>
    <Doughnut :data="chartData" :options="options" />
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'


ChartJS.register(ArcElement, Tooltip, Legend)

let loaded = ref(null);

const options = {
  responsive: true,
  maintainAspectRatio: false
}

const props = defineProps({
  data: {
    type: Object,
  },
})

const dataCreator = (responseData) => {
  const { installs, downloads } = responseData;

  return {
    labels: [
      `Установки (${installs})`,
      `Скачивания (${downloads})`,
    ],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651'],
        data: [installs, downloads]
      }
    ]
  }
};
let chartData = ref(dataCreator({}));

watch(() => props.data, async (newData, oldData) => {
  chartData.value = dataCreator(newData)
}, { immediate: true });
</script>
