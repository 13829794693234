require('./bootstrap');

import { createApp } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VueTheMask from 'vue-the-mask';

import Analytics from './components/analytics/analytics.vue'
import AddCard from './components/banking/AddCard.vue'
import CardItem from './components/banking/CardItem.vue'
import PayModal from './components/banking/PayModal.vue'
import ProlongModal from "./components/banking/ProlongModal.vue";

const app = createApp({})

app.use(VueTheMask);


app.component('analytics', Analytics)
app.component('add-card', AddCard)
app.component('card-item', CardItem)
app.component('pay-modal', PayModal)
app.component('prolong-modal', ProlongModal)
app.component('VueDatePicker', VueDatePicker);

app.mount('#app')
