<template>
  <div class="col-md-4 mb-3">
    <div :class="'card-custom ' + card.discount_card.class + (deleting ? ' deleting' : '')">
      <div class="deleting-wait text-center" v-if="deleting">
        <i class="fa fa-spin fa-spinner fa-lg"></i>
      </div>
      <div class="card-number d-flex justify-content-between">
        <div>
          <span class="font-monospace font-weight-bold">{{ card.card_mask }}</span><br>
          <small>{{ card.discount_card.card_name }} {{ card.discount_card.text }}</small>
        </div>
        <div>
          <div class="text-align-right">
            <i class="far fa-trash-alt cursor-pointer" @click="confirmDeletion(card.id)"></i>
          </div>
          <img :src="'/assets/img/logos/' + card.system_name + '.svg'" alt="Card Icon"
               class="card-icon">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardItem",
  props: {
    card: Object,
  },
  data() {
    return {
      deleting: false
    };
  },
  mounted() {
  },
  methods: {
    async confirmDeletion(cardId) {
      this.deleting = true;
      let deleteConfirm = confirm('Вы действительно хотите удалить эту карту?');
      if (deleteConfirm) {
        let result = await this.makePostRequest('/banking/deactivate-card/' + cardId, []);
        if (result.success) {
          alert('Удалено')
          location.reload();
        } else {
          this.deleting = false;
          alert(result?.message ?? 'Не удалось удалить карту')
          location.reload();
        }
      }
    },
    async makePostRequest(url, data) {
      // Получаем CSRF-токен из мета-тега
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken // Включаем CSRF-токен в заголовок
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
  }
}
</script>

<style scoped>

</style>
