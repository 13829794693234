<template>
  <form @submit.prevent="submitForm">
    <div class="mb-3">
      <label for="cardHolder" class="form-label">Владелец карты</label>
      <input type="text" class="form-control" id="cardHolder" v-model="cardHolder" @input="validateForm" placeholder="ИМЯ ФАМИЛИЯ">
    </div>
    <div class="mb-3">
      <label for="cardNumber" class="form-label">Номер карты</label>
      <div class="input-group">
        <input type="text" class="form-control" id="cardNumber" v-model="cardNumber" @input="validateForm" placeholder="4234 5678 9012 3456"
               v-mask="'#### #### #### ####'">
        <span class="input-group-text">
                                        <img :src="paymentSystemLogo" alt="Payment System Logo" width="24">
                                    </span>
      </div>
      <div v-if="!isValidCardNumber" class="text-danger">Не верный номер карты</div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="expirationDate" class="form-label">Срок годности</label>
        <div class="input-group">
          <input type="text" class="form-control" id="expirationDate" v-model="expirationDate" @input="validateForm" placeholder="01 / 30"
                 v-mask="'## / ##'">
          <span class="input-group-text"><i class="fas fa-calendar"></i></span>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="cvv" class="form-label">CVV</label>
        <input type="text" class="form-control" id="cvv" v-model="cvv" @input="validateForm" maxlength="3" placeholder="***">
      </div>
    </div>
    <div class="alert alert-danger text-sm" v-if="errorMessage?.length > 0">
      {{errorMessage}}
    </div>
    <button type="submit" class="btn btn-primary w-100" :disabled="!isFormValid || adding">{{ btnText }}</button>
    <p style="font-size: 14px; color: #555" class="mb-3" v-if="isConfirmWait">
      <i class="fas fa-spin fa-spinner"></i> Ожидаем подтверждения от Банка
    </p>

    <p style="font-size: 12px; color: #555; margin-top: 10px;">
      Продолжая, вы соглашаетесь с <a href="https://shopuchet.kz/agreement/" target="_blank">Публичной оферте</a> и даете согласие на обработку ваших персональных данных.
    </p>
  </form>
</template>

<script>
export default {
  name: "AddCard",
  data() {
    return {
      cardHolder: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      isValidCardNumber: true,
      isFormValid: false,
      adding: false,
      btnText: 'Добавить карту',
      paymentSystemLogo: '/assets/img/logos/visa.svg',
      errorMessage: null,
      isConfirmWait: false
    };
  },
  mounted() {
  },
  methods: {
    validateForm() {
      this.isValidCardNumber = this.validateCardNumber(this.cardNumber.replace(/\s/g, ''));
      this.isFormValid = this.cardHolder && this.isValidCardNumber && this.expirationDate && this.cvv.length === 3;
      this.setPaymentSystemLogo();
    },
    validateCardNumber(number) {
      if (number?.length >= 16) {
        // Luhn Algorithm for card number validation
        let sum = 0;
        let shouldDouble = false;
        for (let i = number.length - 1; i >= 0; i--) {
          let digit = parseInt(number.charAt(i));
          if (shouldDouble) {
            if ((digit *= 2) > 9) digit -= 9;
          }
          sum += digit;
          shouldDouble = !shouldDouble;
        }
        return sum % 10 === 0;
      }

      return true;
    },
    setPaymentSystemLogo() {
      const number = this.cardNumber.replace(/\s/g, '');
      if (number.startsWith('4')) {
        this.paymentSystemLogo = '/assets/img/logos/visa.svg';
      } else {
        this.paymentSystemLogo = '/assets/img/logos/mastercard.svg';
      }
    },
    async submitForm() {
      this.errorMessage = null;
      this.adding = true;
      this.btnText = 'Добавление...';
      let result = await this.makePostRequest(
        '/banking/save-card',
        {
          card_number: this.cardNumber.replace(/\s+/g, ''),
          card_holder: this.cardHolder,
          cvc: this.cvv,
          exp: this.expirationDate,
        }
      )

      if (result?.success) {
        this.btnText = 'Добавлено';
        location.reload()
      } else {
        if (result?.secure_3d) {
          let secure3d = result.secure_3d;
          this.isConfirmWait = true;
          this.send3dSecure(secure3d.action, secure3d.paReq, secure3d.md, result.id)
        } else {
          this.adding = false;
          this.btnText = 'Добавить карту';
          this.errorMessage = result?.message;
        }
      }
      this.btnText = 'Добавить карту';
    },
    async makePostRequest(url, data) {
      // Получаем CSRF-токен из мета-тега
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken // Включаем CSRF-токен в заголовок
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    send3dSecure(action, paReq, md, cardId) {
      let tempUrl = `${window.location.origin}/3dRes?card_id=${cardId}`;
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = action;
      form.target = '_self';

      const paReqInput = document.createElement('input');
      paReqInput.type = 'hidden';
      paReqInput.name = 'PaReq';
      paReqInput.value = paReq;
      form.appendChild(paReqInput);

      const mdInput = document.createElement('input');
      mdInput.type = 'hidden';
      mdInput.name = 'MD';
      mdInput.value = md;
      form.appendChild(mdInput);

      const termUrlInput = document.createElement('input');
      termUrlInput.type = 'hidden';
      termUrlInput.name = 'TermUrl';
      termUrlInput.value = termUrl;
      form.appendChild(termUrlInput);

      document.body.appendChild(form);
      form.submit();

      document.body.removeChild(form);
    }
  }
}
</script>

<style scoped>

</style>
