<template>
  <div class="row mx-1">
    <div class="col-12">
      <div class="row">
        <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
          <div class="card mb-4">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Клиентов</p>
                    <h5 class="font-weight-bolder mb-0">
                      {{ commonData.users_total }}
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                    <i class="fa-solid fa-user text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
          <div class="card mb-4">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Ключей</p>
                    <h5 class="font-weight-bolder mb-0" v-if="commonData.keys_total">
                      {{ commonData.keys_active_total }}
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                    <i class="fa-solid fa-key text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-4 col-sm-6 col-xs-12">
      <label>Выбрать диапазон</label>
      <VueDatePicker
        locale="ru"
        v-model="dateDiapason"
        :enable-time-picker="false"
        :range="true"
        select-text="Выбрать"
        cancel-text="Отмена"
        format="yyyy-MM-dd"
      />
    </div>
    <div class="col-12 my-3" v-if="emptyData">
      <h2>По выбранному диапазону нет данных</h2>
    </div>
    <div class="col-12 mt-3" v-if="!emptyData">
      <div class="card">
        <div class="card-body py-3">
          <div class="row" v-if="!loadedInstallsDownloads">
            <div class="d-flex justify-content-center my-1">
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="row" v-if="loadedInstallsDownloads">
            <div class="col-xl-4 col-md-4 col-sm-6 col-xs-12">
              <div class="text-sm">
                <label class="m-0"><b>Количество:</b></label>
                <p class="text-xs mb-0 font-weight-bold">
                  Куплено: <b class="text-sm" v-if="purchasesData.added_sum">{{ purchasesData.added_sum }}</b> Прошлый период: <b
                  class="text-sm" v-if="purchasesData.added_last_period_sum">{{ purchasesData.added_last_period_sum }}</b>
                </p>
                <p class="text-xs mb-0 font-weight-bold">
                  Продлено: <b class="text-sm" v-if="purchasesData.extended_sum">{{ purchasesData.extended_sum }}</b> Прошлый период: <b class="text-sm" v-if="purchasesData.extended_last_period_sum">{{ purchasesData.extended_last_period_sum }}</b>
                </p>
              </div>
            </div>
            <div class="col-xl-5 col-md-5 col-sm-6 col-xs-12">
              <div class="text-sm" >
                <label class="m-0"><b>Финансы:</b></label>
                <p class="text-xs mb-0 font-weight-bold">
                  Куплено: <b class="text-sm" v-if="purchasesMoneyData.added_sum">{{ amountBeautify(purchasesMoneyData.added_sum) }} ⍑</b> Прошлый период:
                  <b class="text-sm" v-if="purchasesMoneyData.added_last_period_sum">{{ amountBeautify(purchasesMoneyData.added_last_period_sum) }} ⍑</b>
                </p>
                <p class="text-xs mb-0 font-weight-bold">
                  Продлено: <b class="text-sm" v-if="purchasesMoneyData.extended_sum">{{ amountBeautify(purchasesMoneyData.extended_sum) }} ⍑</b> Прошлый
                  период: <b class="text-sm" v-if="purchasesMoneyData.extended_last_period_sum">{{ amountBeautify(purchasesMoneyData.extended_last_period_sum) }} ⍑</b>
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6 col-xs-12">
              <div class="text-sm">
                <label class="m-0"><b>Установлено:</b></label>
                <p class="text-xs mb-0 font-weight-bold">
                  Установлено: <b
                  class="text-sm" v-if="downloadInstallData.installs">{{ downloadInstallData.installs }}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 nav-wrapper position-relative end-0 mt-3" v-if="dateDiapason && !emptyData">
      <ul class="nav nav-pills nav-fill p-1 col-12" role="tablist">
        <li class="nav-item" v-for="tab in tabs" role="presentation">
          <a
            href="#"
            class="nav-link"
            :class="{'active': tab.key === currentTab}"
            data-bs-toggle="tab"
            role="tab"
            :aria-selected="tab.key === currentTab"
            @click.stop="setTab(tab.key)"
          >
            {{ tab.label }}
          </a>
        </li>
      </ul>
      <div class="tab-content mt-3">
        <KeyAnalytics
          v-if="currentTab === TAB_KEY_PURCHASES && purchasesData"
          :data="purchasesData"
        />
        <KeyAnalytics
          v-if="currentTab === TAB_KEY_AMOUNT && purchasesMoneyData"
          :data="purchasesMoneyData"
        />
        <InstallDownload
          v-if="currentTab === TAB_DOWNLOADS && downloadInstallData"
          :data="downloadInstallData"
        />
        <TotalBar
          v-if="currentTab === TAB_CITIES"
          :data="citiesData"
          chart-label="Пользователи по городам"
        />
        <TotalBar
          v-if="currentTab === TAB_BUSINESS_CATEGORIES"
          :data="businessCategoriesData"
          chart-label="Пользователи по категориям"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { subDays } from 'date-fns'
import KeyAnalytics from "./key-amount-count-analytics.vue";
import InstallDownload from "./install-download.vue";
import axios from "axios";
import TotalBar from "./total-bar.vue";

let chartRef = ref(null);

let dateDiapason = ref(null);

let commonData = ref({});

let citiesData = ref([]);

let businessCategoriesData = ref([]);

onMounted(async () => {
  initDateDiapason();
  commonData.value = await getCommonData();
});

const initDateDiapason = () => {
  const date = new Date();

  dateDiapason.value = [subDays(date, 8), subDays(date, 1)];
}

const TAB_KEY_PURCHASES = 'key-purchases';
const TAB_KEY_AMOUNT = 'key-amount';
const TAB_DOWNLOADS = 'count-of-download';
const TAB_CITIES = 'cities';
const TAB_BUSINESS_CATEGORIES = 'business_categories';

const tabs = [
  {
    'label': 'Количество',
    'key': TAB_KEY_PURCHASES
  },
  {
    'label': 'Стоимость',
    'key': TAB_KEY_AMOUNT
  },
  {
    'label': 'Скачивания / Установки',
    'key': TAB_DOWNLOADS
  },
  {
    'label': 'Города',
    'key': TAB_CITIES
  },
  {
    'label': 'Категории бизнесов',
    'key': TAB_BUSINESS_CATEGORIES
  },
];
const currentTab = ref(TAB_KEY_PURCHASES)

const setTab = (tabKey) => {
  currentTab.value = tabKey;
}

const loaded = ref(false);
const loadedInstallsDownloads = ref(false);
const downloadInstallData = ref(null);
const purchasesData = ref(null);
const purchasesMoneyData = ref(null);

const getDownloadInstallData = async (startDate, endDate) => {
  loadedInstallsDownloads.value = false;
  const {data} = await axios.get('api/analytics/download-install', {
    params: {
      startDate,
      endDate,
    }
  });
  loadedInstallsDownloads.value = true;
  return data
}

const getPurchasesData = async (startDate, endDate, kind) => {
  const {data} = await axios.get('api/analytics/purchases', {
    params: {
      startDate,
      endDate,
      kind,
    }
  });

  return data
}

const getCommonData = async () => {
  const {data} = await axios.get('api/analytics/common');

  return data
}

const getCitiesData = async () => {
  const {data} = await axios.get('api/analytics/cities');
  return data;
}

const getBusinessCategoriesData = async () => {
  const {data} = await axios.get('api/analytics/business-categories');
  return data;
}

watch(dateDiapason, async (newDiapason, oldDiapason) => {
  purchasesData.value = await getPurchasesData(newDiapason[0], newDiapason[1]);
  purchasesMoneyData.value = await getPurchasesData(newDiapason[0], newDiapason[1], 'count_money');
  downloadInstallData.value = await getDownloadInstallData(newDiapason[0], newDiapason[1])
});

watch(currentTab, async (curTab) => {
  if (curTab === TAB_CITIES) {
    citiesData.value = await getCitiesData();
  }

  if (curTab === TAB_BUSINESS_CATEGORIES) {
    businessCategoriesData.value = await getBusinessCategoriesData();
  }
})

const amountBeautify = (amount) => {
  return amount.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    {minimumFractionDigits: 0}
  );
}

const emptyData = computed(() => {
  return false; // purchasesData.value?.added_sum === 0 && downloadInstallData.value?.downloads === 0 && purchasesData.value?.extended_sum;
})

</script>
<style lang="scss">
.nav.nav-pills .nav-link.active {
  background-color: #fff;
}
</style>
