<template>
  <Bar :data="chartData" :options="options" />
</template>
<script setup>
import {ref, watch} from "vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  // maintainAspectRatio: false
  scales: {
    y: {
      beginAtZero: true
    }
  }
};
const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  chartLabel: {
    type: String,
    default: '',
  }
})

/**
 * @param apiData
 * @returns {{datasets: [{backgroundColor: string[], borderColor: string[], data: *[], borderWidth: number, label: string}], labels: *[]}}
 */
const dataCreator = (apiData) => {
  let labels = [];
  let data = [];

  if (apiData) {
    apiData = apiData.sort((a,b) => b.total - a.total);

    labels = apiData.map(({name, total}) => {
      if (!name) {
        return `Не определен (${total})`
      }
      return `${name} (${total})`
    });
    data = apiData.map(({total}) => total);
  }

  return {
    labels,
    datasets: [
      {
        label: props.chartLabel,
        data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 1,
      }
    ]
  }
};

let chartData = ref(dataCreator([]));

watch(() => props.data, async (newData, oldData) => {
  chartData.value = dataCreator(newData)
}, { immediate: true });
</script>
